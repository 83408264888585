// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';

// icons

import { Fade } from 'react-reveal';
import { useNavigate } from 'react-router-dom';

import TICK from 'assets/images/tick.webp'

const Thanks = () => {
    const { t } = useTranslation();
    const [countdown, setCountdown] = useState(10); // Set the initial countdown value
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);

            if (countdown <= 0) {
                clearInterval(timer);
                navigate('/home');
            }
        }, 1000);

        // Clear the interval when the component is unmounted
        return () => clearInterval(timer);
    }, [countdown, navigate]);

    


    return (

        <>
            <Fade bottom >
                <div className='container __articles_list' style={{ paddingTop: '20vh', paddingBottom: '6%' }}>
                    <div className='inner'>
                        <div className="c-heading text-center">

                            <div className="c-heading__middle">
                                <h3 className="heading-3 text-uppercase">You have successfully subscribed!</h3>
                            </div>

                            <div className="c-heading__bottom">
                                <div className="c-heading__short-desc">
                                    <p class="__sub_complete_descr mt-3">Redirecting in {countdown} seconds...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='block mt-5'>
                        <div className='inner'>
                            <div className='row justify-content-center'>
                                <div class="__sub_complete_img_sec">
                                        <img src={TICK} alt="success" />
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fade>
        </>
    );
};

export default Thanks;
