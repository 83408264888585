// Core
import React, { useEffect, useRef, useState } from 'react';

// Styles
import './styles.scss';

import { ArrowLeftOutlined, ArrowRightOutlined ,LikeOutlined, ShareAltOutlined, SendOutlined, LikeFilled, CommentOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import ViewArticleModal from 'components/modals/ViewArticleModal';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal, Skeleton, message, Button as ButtonAntD, Spin } from 'antd';
import SocialShare from 'components/modals/SocialShare';
import EditComment from 'components/modals/EditComment';

import TimeAgo from 'react-timeago'
import TextArea from 'components/commonComponents/TextArea';
import Button from 'components/commonComponents/Button';
import Login from 'components/modals/Login';
import ReactPlayer from 'react-player';
import Highlight from 'components/commonComponents/Highlight';
import InputField from 'components/commonComponents/InputField';
import { APP_ID } from 'config/constants';
import FAQ from 'components/modals/FAQ';
import QA from 'assets/images/question-and-answer.png'

const ViewArticle = () => {
    const { currentLang, userDetails } = useSelector((store) => store.commonData);
    const navigate = useNavigate();
    const { articleId } = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [articles, setRelatedArticles] = useState([])
    const [isLoadingArticles, setIsLoadingArticles] = useState(false)
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('')
    const { t } = useTranslation()
    const [openShareSocial, setOpenShareSocial] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();
    const [totalLikes, setTotalLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);

    const [comments, setComments] = useState([]);
    const [errorComment, setErrorComment] = useState('')
    const [commentData, setCommentData] = useState({})
    const [isLoadingComment, setIsLoadingComments] = useState(false);
    const [isLoadingMoreComment, setIsLoadingMoreComments] = useState(false);

    const [isLastPage, setIsLastPage] = useState(false);
    const [openDeleteComment, setOpenDeleteComment] = useState(false);
    const { comment = '' } = commentData;
    const [openEditComment, setOpenEditComment] = useState('');
    const commentsRef = useRef(null);
    const [commentPage, setCommentPage] = useState(1)

    const [showLogin, setShowLogin] = useState(false);

    const [searchParams] = useSearchParams();
    const [searchkey, setSearchkey] = useState(searchParams.get('searchkey'))

    const [isLoadingCht, setIsLoadingCht] = useState(false)
    const [chatgptResponse, setChatgptResponse] = useState('')

    const [datacht, setDataCht] = useState({});
    const [error, setError] = useState('');
    const [openShareChat, setOpenShareChat] = useState(false);

    const [showFAQ, setShowFAQ] = useState(false)

    const { searchPhrase } = datacht

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchArticle();
        fetchComments();
        fetchArticlesLike();
        setSearchkey(searchParams.get('searchkey'))
    }, [articleId, currentLang]);


    const fetchArticle = async () => {
        window.scrollTo(0, 0);
        setIsLoading(true)
        const res = await fetchData(restAPIs.articlesListing(currentLang?.id, 1, articleId));
        setData(res.articles[0])
        fetchArticles(res?.articles[0].category)
        setIsLoading(false);
    }

    const fetchArticles = async (id) => {
        setIsLoadingArticles(true)
        const res = await fetchData(restAPIs.articlesListing(currentLang?.id, 1, '', '', +id));
        setRelatedArticles(res.articles)
        setIsLoadingArticles(false);
    }

    const handleArticleNavigate = async (id) => {
        setData([])
        window.scrollTo(0, 0);
        setIsLoading(true)
        navigate('/article/' + id)
        const res = await fetchData(restAPIs.articlesListing(currentLang?.id, 1, id));
        setData(res.articles[0])
        fetchArticles(res?.articles[0].category)
        setIsLoading(false);
    }

    const handleOpenViewArticle = (data) => {
        setContent(data)
        setOpen(true)
    }



    const transformDate = (date) => {
        const currentDate = new Date(date)
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const day = currentDate.getDate();
        const month = currentDate.getMonth(); // Month is zero-based, so add 1
        const year = currentDate.getFullYear();
        const formattedDate = `${day} ${monthNames[month]}, ${year}`;
        return formattedDate
    }

    const handleOpenShareSocial = () => {
        setOpenShareSocial(true);
    };
    const handleCloseShareSocial = () => {
        setOpenShareSocial(false);
    };

    const handleOpenShareChat = () => {
        setOpenShareChat(true);
    };
    const handleCloseShareChat = () => {
        setOpenShareChat(false);
    };

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };



    const fetchArticlesLike = async () => {
        const formData = new FormData();
        formData.append('articleId', articleId)
        const res = await fetchData(restAPIs.likeArticle(formData));
        setIsLiked(res.isLiked)
        setTotalLikes(res.totalLikes)
    }

    const likeArticle = async () => {
        if (userDetails) {
            const formData = new FormData();
            formData.append('action', isLiked ? 'delete' : 'add');
            formData.append('articleId', articleId);
            const res = await fetchData(restAPIs.likeArticle(formData));
            if (res.statusCode === 200) {
                setIsLiked(!isLiked)
                setTotalLikes(+res.totalLikes)
                messageApi.open({
                    type: 'success',
                    content: res.errormessage,
                });
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: res.errormessage,
                });
            }
        }
        else {
            setShowLogin(true)
        }
    }



    const fetchComments = async () => {
        setCommentPage(1)
        setIsLoadingMoreComments(true)
        const formData = new FormData();
        formData.append('articleId', articleId);
        const res = await fetchData(restAPIs.commentArticle(formData));
        if (res?.statusCode === 200) {
            setComments(res.comments);
            setIsLastPage(res.isLastPage)
            setIsLoadingMoreComments(false)
        }
    };


    const fetchMoreComments = async () => {
        let page = commentPage + 1
        setCommentPage(page)
        setIsLoadingMoreComments(true)
        const formData = new FormData();
        formData.append('articleId', articleId);
        formData.append('page', page);
        const res = await fetchData(restAPIs.commentArticle(formData));
        if (res?.statusCode === 200) {
            setComments(comments.concat(res.comments));
            setIsLastPage(res.isLastPage)
            setIsLoadingMoreComments(false)

        }
    };

    const handleOpenLoginModel = () => {
        setShowLogin(true);
    }

    const handleOnChangeComment = (key) => (value) => {
        setErrorComment({
            ...errorComment,
            [key]: null
        });
        setCommentData({
            ...commentData,
            [key]: value
        });
    };

    const handleSubmitComment = async () => {
        const errors = {
            comment: !comment,
        };
        setErrorComment(errors);
        let isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        setIsLoadingComments(true);
        const formData = new FormData();
        formData.append('action', 'add');
        formData.append('articleId', articleId);
        formData.append('comment', comment);
        const res = await fetchData(restAPIs.commentArticle(formData));
        if (res.statusCode === 200) {
            fetchComments();
            setCommentData({})
            messageApi.open({
                type: 'success',
                content: res.errormessage,
            });
        }
        else {
            messageApi.open({
                type: 'error',
                content: res.errormessage,
            });
        }
        setIsLoadingComments(false);
    };

    const handleCloseLoginModel = () => {
        setShowLogin(false);
    };


    const handleDeleteCommentClick = () => {
        setOpenDeleteComment(true);
    };

    const handleEditCommentClick = (value) => {
        setOpenEditComment(value);
    };

    const handleCloseEditComment = (value) => {
        setOpenEditComment('');
        if (value)
            fetchComments();
    }

    const handleCloseDeleteComment = async (action, commentId) => {
        setOpenDeleteComment(false);
        if (action) {
            const formData = new FormData();
            formData.append('action', 'delete');
            formData.append('articleId', articleId);
            formData.append('commentId', commentId);
            const res = await fetchData(restAPIs.commentArticle(formData));
            if (res.statusCode === 200) {
                fetchComments();
                messageApi.open({
                    type: 'success',
                    content: res.errormessage,
                });
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: res.errormessage,
                });
            }
        }
    };

    const gotoComments = () => {
        commentsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    const highlightText = (text, highlight) => {
        const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
        let highlightedHTML = '';
        parts?.forEach((part, i) => {
            if (part.toLowerCase() === highlight.toLowerCase()) {
                highlightedHTML += `<span key=${i} style="font-weight: bold; color: red;">${part}</span>`;
            } else {
                highlightedHTML += part;
            }
        });
        return highlightedHTML;
    };

    
    const handleOnChange = (key) => (value) => {
        setError({
            ...error,
            [key]: null
        });
        setDataCht({
            ...datacht,
            [key]: value
        });
    };


    const handleSaveChtGpt = async () => {

        if (userDetails) {
            if (userDetails?.subscription != 0) {
                const errors = {
                    searchPhrase: !searchPhrase,
                };
                setError(errors);
                let isFormValid = Object.values(errors).every((item) => !item);
                if (!isFormValid) return;
                setIsLoadingCht(true)
                const formData = new FormData();
                formData.append('appId', APP_ID);
                formData.append('language', currentLang?.id);
                formData.append('searchphrase', btoa(unescape(encodeURIComponent(searchPhrase))))
                formData.append('subject', btoa(unescape(encodeURIComponent(`${data?.name}, ${data?.body}.`))))
                formData.append('subjectId', articleId);
                const res = await fetchData(restAPIs.chatGPT(formData));
                if (res.statusCode === 200) {
                    setChatgptResponse({ answer: res?.answer, question: res?.question })
                    setIsLoadingCht(false)
                }
            }
            else {
                navigate('/subscribe/'+APP_ID)
            }
        }
        else {
            setShowLogin(true)
        }
    }


    const handleNavigateFAQ = () => {
        userDetails ? userDetails?.subscription != 0 ? setShowFAQ(true) : navigate('/subscribe/'+APP_ID) : setShowLogin(true)
    }

    const handleCloseModelFAQ = () => {
        setShowFAQ(false)
    }



    return (
        <>
            {isLoading ?
                <>
                    <div className='pt-5'>
                        <div className='container' style={{ paddingTop: '10%', paddingBottom: '6%' }}>
                            <div className='inner'>
                                <div className="head-toolbar is-sm-center">
                                    <div className="head-toolbar__left">
                                        <a className="bt-ico-left"> <Skeleton.Button active /></a>
                                    </div>
                                    <div className="head-toolbar__right">
                                        <div className="head-toolbar__right">

                                            <ul className="toolbar-bts">
                                                <li key={1}>
                                                    <Skeleton.Button active />
                                                </li>
                                                {/* <li key={2}>
                                                    <Skeleton.Button active />
                                                </li> */}
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="inner">
                                <div className="c-work" style={{ height: '80vh' }}>
                                    <div className="c-work__left">
                                        <div className="m-asset">
                                            <div className="m-asset__header" style={{ width: '100%' }}>
                                                <h3 className="m-asset__title"><Skeleton.Button active style={{ width: '300px' }} /></h3>
                                            </div>
                                            <div className="m-asset__buy">
                                                <div className="m-asset__from">
                                                    <a >
                                                        <div><small><Skeleton.Button active /></small></div>
                                                    </a>
                                                </div>
                                                <div className="m-asset__from">
                                                    <a >
                                                        <div><small><Skeleton.Button active /></small></div>
                                                    </a>
                                                </div>
                                                <div className="m-asset__from">
                                                    <a >
                                                        <div><small><Skeleton.Button active /></small></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="m-asset__desc">
                                                <Skeleton active />
                                            </div>
                                            <div className="m-asset__footer">
                                                <div className="m-asset__curated">
                                                    <Skeleton.Button active />
                                                </div>
                                                <div className="m-asset__date">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c-work__right __inside_detail" >
                                        <div style={{ width: '100%' }}>
                                            <Skeleton.Image active style={{ height: '60vh', width: '100% ' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

                :
                <div className='pt-5'>
                    <div className='container' style={{ paddingTop: '10%', paddingBottom: '6%' }}>
                        <div className='inner'>
                            <div className="head-toolbar is-sm-center">
                                <div className="head-toolbar__left">
                                    <a className="bt-ico-left" onClick={() => navigate(-1)}> <ArrowLeftOutlined style={{ paddingBottom: '2px' }} />{t('back')}</a>
                                </div>
                                <div className="head-toolbar__right">
                                    <div className="head-toolbar__right">

                                        <ul className="toolbar-bts">
                                            <li key={1}>
                                                <ShareAltOutlined onClick={handleOpenShareSocial} />
                                            </li>
                                            {/* <li key={2}>
                                                <InfoCircleOutlined />
                                            </li> */}
                                        </ul>

                                        <SocialShare
                                            open={openShareSocial}
                                            onClose={handleCloseShareSocial}
                                            title={data?.name}
                                            description={data?.subbody}
                                            image={data?.publicationIcon}
                                            url={window.location.href}
                                            summary={data?.subbody}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inner" style={{ minHeight: '60vh' }}>
                            <div className="c-work_detail" style={{ height: 'auto' }}>
                                <div className="c-work_detail__left" style={{ height: '100%' }}>
                                    <div className="m-asset">
                                        <div className='__categ'>
                                            <a style={{cursor:'pointer'}} onClick={() => navigate('/general-articles?categoryId=' + data?.category)}><strong>{data?.categoryName}</strong></a>
                                        </div>
                                        <div className="m-asset__header">
                                            <h3 className="m-asset__title"><Highlight text={data?.name ? data.name : ''} highlight={searchkey} /></h3>
                                        </div>
                                        <div className="m-asset__footer">
                                            <div className="m-asset__date">
                                                {transformDate(data?.creationdate)}
                                            </div>
                                        </div>
                                        <div className="m-asset__desc">
                                            {searchkey ?
                                                <div dangerouslySetInnerHTML={{ __html: highlightText(data?.body, searchkey) }} style={{ fontSize: '20px' }}></div>
                                                : <p dangerouslySetInnerHTML={{ __html: data?.body }} style={{ fontSize: '20px' }}></p>
                                            }

                                            {data && data?.videoURL && <div className='__video_sec mt-5 mb-5'>
                                                <div className='player-wrapper'>
                                                    <ReactPlayer url={data?.videoURL} controls={true}
                                                        className='react-player'
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                </div>
                                            </div>}
                                        </div>

                                        <div className="_cht_full">
                                            <h4 className="__chat_heading">{t('Ask_about_this_article_and_get_your_answers_instantly')}</h4>
                                            <div className="__input_gpt">
                                                <InputField
                                                    type="text"
                                                    placeholder={t('ask_anything')}
                                                    onChange={handleOnChange('searchPhrase')}
                                                    error={error.searchPhrase}
                                                    value={searchPhrase}
                                                    name='searchPhrase'
                                                />

                                                <Button
                                                    label={t('send')}
                                                    icon={<ArrowRightOutlined />}
                                                    handleOnClick={handleSaveChtGpt}
                                                    withLoader
                                                    loading={isLoadingCht}
                                                    disabled={isLoadingCht}
                                                />

                                            </div>
                                            <p className='mt-2' dir={currentLang?.id != 1 ? 'rtl' : 'ltr'}> <a className='__faq' onClick={() => handleNavigateFAQ()}><img src={QA} style={{ width: '22px' }} />{t('frequently_asked_questions')}</a></p>
                                            {chatgptResponse && <div className="__resp_gpt" >
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <h3 style={{margin:0}}>{chatgptResponse?.question}</h3>
                                                    <a className='__share' onClick={()=>handleOpenShareChat()}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z" /></svg></a>
                                                    <SocialShare
                                                        open={openShareChat}
                                                        onClose={handleCloseShareChat}
                                                        title={chatgptResponse?.question}
                                                        description={chatgptResponse?.answer}
                                                        image={data?.publicationIcon}
                                                        url={window.location.href}
                                                        summary={chatgptResponse?.answer}
                                                    />
                                                </div>
                                                <p dangerouslySetInnerHTML={{ __html: chatgptResponse?.answer }}></p>
                                            </div>}
                                        </div>

                                        <div className='__comment_sec'>
                                            <div className='__comnt_icon_set'>

                                                <a style={{ color: isLiked ? '#ff0000' : 'inherit' }} onClick={() => likeArticle()}>{isLiked ? <LikeFilled /> : <LikeOutlined />} <p>{totalLikes && totalLikes} {t('likes')}</p></a>
                                                <a><CommentOutlined /> <p>{comments && comments.length} {t('comments')}</p></a>
                                            </div>
                                        </div>

                                        <div className='__comment_sec d-flex flex-column' >
                                            <h3 className='__common-head'>{t('comments')} ({comments && comments.length})</h3>
                                            {comments && comments.length === 0 ? 'No Comments' : ''}
                                            {
                                                comments && comments?.map((item, idx) => {
                                                    return (
                                                        <div className='__com-ed-wrap' key={idx + 'i'}>
                                                            <div className='__comment_wrap'>
                                                                <img src={item.profilePic} alt={item?.fullName} />
                                                                <div style={{ margin: '0 15px' }}>
                                                                    <h1>{item.fullName} <span> <TimeAgo date={item?.commentDate} /></span></h1>
                                                                    <p>{item.comment}</p>
                                                                </div>
                                                            </div>
                                                            {item.isOwner ? <div className='__com_actions'>
                                                                <button onClick={() => handleEditCommentClick(idx)}><EditFilled /></button>

                                                                <EditComment
                                                                    key={idx}
                                                                    open={openEditComment === idx}
                                                                    onClose={handleCloseEditComment}
                                                                    commentData={item.comment}
                                                                    articleId={articleId}
                                                                    commentId={item.commentId}
                                                                />
                                                                <button onClick={() => handleDeleteCommentClick()}><DeleteFilled /></button>

                                                                <Modal
                                                                    open={openDeleteComment}
                                                                    onClose={handleCloseDeleteComment}
                                                                    className='__common_model_class'
                                                                    footer={null}
                                                                    closable={false}
                                                                >
                                                                    <h3 className='____common_model_class_head' style={{ marginBottom: '10px' }}>
                                                                        {t('delete')}
                                                                    </h3>

                                                                    <div className='.____common_model_class_desc'>
                                                                        <p style={{ whiteSpace: 'pre-line', marginBottom: 0 }}>Are you sure you want to remove this comment !</p>
                                                                    </div>
                                                                    <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
                                                                        <a onClick={() => handleCloseDeleteComment(false)} style={{ cursor: 'pointer' }}>{t('cancel')}</a>
                                                                        <Button
                                                                            label={t('ok')}
                                                                            handleOnClick={() => handleCloseDeleteComment(true, item.commentId)}
                                                                            style={{ margin: '0' }}
                                                                        />
                                                                    </div>
                                                                </Modal>

                                                            </div> : ''}
                                                        </div>
                                                    )
                                                })
                                            }
                                            {isLoadingMoreComment ?
                                                <Spin className='__spin_color' style={{ margin: '10px 0' }} size="large" tip="Loading" /> : ''}
                                            {!isLastPage && <p><ButtonAntD onClick={() => fetchMoreComments()}>{t('more')}</ButtonAntD></p>}

                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className='__add_comment_wrap'>
                                                        {/* <span className='mb-2'>{t('read_smtg_u_like')}</span> */}
                                                        {
                                                            userDetails && userDetails?.userid ?

                                                                <div className='__comment_adding'>
                                                                    <img src={userDetails?.profile_pic} />
                                                                    <div className='__newStyle_btn' style={{ position: 'relative' }}>
                                                                        <TextArea
                                                                            type="text"
                                                                            placeholder={t('your_comment')}
                                                                            height={110}
                                                                            value={comment}
                                                                            onChange={handleOnChangeComment('comment')}
                                                                            error={errorComment.comment}
                                                                        />
                                                                        <Button
                                                                            icon={<SendOutlined />}
                                                                            handleOnClick={handleSubmitComment}
                                                                            withLoader
                                                                            loading={isLoadingComment}
                                                                            disabled={isLoadingComment}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                :
                                                                <>
                                                                    <Button
                                                                        label={t('write_a_comment')}
                                                                        className='mt-4'
                                                                        handleOnClick={handleOpenLoginModel}
                                                                        style={{ margin: '0' }}
                                                                    />
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {contextHolder}
                                    </div>
                                </div>
                                <div className="c-work_detail__right"  dir='ltr'>
                                    <img alt="" className='imageLoader' src={data?.publicationIcon} style={{ objectFit: 'cover' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='__articles_list mt-5'>
                        <div className='inner'>
                            <div className="c-heading text-center">
                                <div className="c-heading__middle">
                                    <h3 className="heading-2 text-uppercase" style={{ fontSize: 'clamp(26px, 3vw, 36px)' }}>{t('related_articles')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="block __articles">
                            <div className='inner' style={{ padding: '0 39px' }}>
                                <Slider {...settings}>
                                    {articles?.map((item, idx) => {
                                        return (
                                            <div className="grid-academy__item" key={idx}>
                                                <div className="card-academy" style={{ margin: '20px' }}>
                                                    <figure className="card-academy__figure">
                                                        <a onClick={() => handleArticleNavigate(item?.id)}>
                                                            <img className="card-academy__media lazy lazy--loaded" src={item?.publicationIcon} />
                                                        </a>
                                                    </figure>
                                                    <div className="card-academy__info">
                                                        <div className="card-academy__header">
                                                            <div className="card-academy__row" style={{ flexDirection: 'column' }}>
                                                                <p>{transformDate(item?.creationdate)}</p>
                                                                <h3 className="card-academy__title __webClam1" style={{ fontWeight: 'bold' }}><a onClick={() => handleArticleNavigate(item?.id)}>{item?.name}</a></h3>
                                                                <span className="card-academy__desc __webClam2">{item?.subbody}</span>
                                                                <a className="__anchor" onClick={() => handleOpenViewArticle(item)}>{t('view_article')}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        )
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>

                </div>
            }
            <ViewArticleModal open={open} onClose={() => setOpen(false)} content={content} />
            <Login
                open={showLogin}
                onClose={handleCloseLoginModel}
            />

            <FAQ
                open={showFAQ}
                onClose={handleCloseModelFAQ}
                subjectId={articleId}
            />
        </>
    );
};

export default ViewArticle;
